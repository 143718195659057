import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "./BaseFragment";
import {Box, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import {DIVIDER, PD_MD, PD_SM, SZ_MD} from "./dimens";
import {StyledBoxRow, StyledSpan} from "./StyledComponents";
import {BaseApp} from "./BaseApp";
import {lightGray} from "./colors";

export type FooterTabFragmentProps = BaseFragmentProps & {}

export type FooterTabFragmentState = BaseFragmentState & {
  title?: string,
}

export abstract class FooterTabFragment<P extends FooterTabFragmentProps = FooterTabFragmentProps, S extends FooterTabFragmentState = FooterTabFragmentState> extends BaseFragment<P, S> {

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      title: this.getTitle(),
    };
  }

  getCustomTitle(): ReactElement | null {
    return null;
  }

  getTitle(): string {
    return null;
  }

  protected renderContainerContent(): ReactElement {
    const toolbar = this.renderToolbar();
    const customTitle = this.getCustomTitle();
    let backgroundColor = BaseApp.CONTEXT.getAppConfig()?.theme.palette.background.default || lightGray;// ((this.styleFlags() & PageFragment.STYLE_CONTAINER_APPEARANCE_MASK) === PageFragment.STYLE_CONTAINER_WASH_FLAG) ? this.theme.palette.background.paper : null;
    return <Box className="footer-tab-fragment-header" style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      {this.state.title || customTitle
        ? <Box style={{
          display: "flex",
          flexShrink: 0,
          height: SZ_MD,
          alignItems: "center",
          paddingLeft: PD_MD,
          paddingRight: PD_MD,
          gap: PD_MD,
          pointerEvents: "none",
          position: "fixed",
          zIndex: 2,
          left: 0,
          right: 0,
        }}>
          <Box style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}>
            {customTitle || <Typography style={{
              fontFamily: "Gabarito, sans-serif",
              fontSize: "200%",
              fontWeight: "bold"
            }}>{this.state.title}</Typography>}
          </Box>
        </Box>
        : null}
      <Box style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: SZ_MD,
        flexGrow: 1,
        position: "relative",
        backgroundColor: backgroundColor
      }}>
        {toolbar
          ? <StyledBoxRow style={{padding: PD_SM, borderBottom: DIVIDER}}>
            <StyledSpan/>
            {toolbar}
          </StyledBoxRow>
          : null}
        {this.renderContent()}
      </Box>
    </Box>
  }

  renderToolbar(): ReactElement {
    return null;
  }

  renderContent(): ReactElement {
    return null;
  }
}
