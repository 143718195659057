import {Game} from "./types";
import {PathProps} from "../index";
import {App} from "./App";
import {GameFragment} from "./games/GameFragment";
import {$KTS, KeyTextStrings} from "../shared/types";

enum GameType {
  SNAKE = "snake",
  REPEAT = "repeat",
  CONNECT = "connect",
  TTT = "ttt",
  MAZE = "maze",
  DINO = "dino",
  TANK = "tank",
  CHAIN = "chain",
  HANOI = "hanoi",
  SHUNTING = "shunting",
}

const GAME_TYPE_KTS = new KeyTextStrings([
  $KTS(GameType.SNAKE, "Snake"),
  $KTS(GameType.REPEAT, "Repeat"),
  $KTS(GameType.CONNECT, "Connect"),
  $KTS(GameType.TTT, "Tic Tac Toe"),
  $KTS(GameType.MAZE, "Amazing Maze"),
  $KTS(GameType.DINO, "Dino Run"),
  $KTS(GameType.TANK, "Tank"),
  $KTS(GameType.CHAIN, "Chain Reaction"),
  $KTS(GameType.HANOI, "Tower of Hanoi"),
  $KTS(GameType.SHUNTING, "Train Shunting"),
]);

export const GAMES: Game[] = Object.getOwnPropertyNames(GameType).map(type => {
  const game: Game = {
    id: GameType[type],
    name: GAME_TYPE_KTS.findText(GameType[type]),
    icon: "/games/" + GameType[type] + "/icon.png",
    show: (path: PathProps) => App.CONTEXT.showFullscreenDialog(() => <GameFragment path={path} game={game}/>),
  };
  return game;
})