import React, {ReactElement} from "react";
import {StyledContainer} from "../../shared/StyledComponents";
import {getAuth} from "@firebase/auth";
import {Game} from "../types";
import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import Confetti from "react-confetti";
import {PD_XLG} from "../../shared/dimens";

export type GameFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  game: Game,
}

export type GameFragmentState = FullscreenDialogWithTitleFragmentState & {
  completed?: boolean,
}

export class GameFragment extends FullscreenDialogWithTitleFragment<GameFragmentProps, GameFragmentState> {

  protected readonly auth = getAuth();

  getTitle(): string {
    return this.props.game.name;
  }

  renderContent(): React.ReactElement {
    return <StyledContainer size={"xlg"} style={{marginTop: PD_XLG}}>
      {this.renderGameContent()}
      {Boolean(this.state.completed)
        ? <Confetti
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            zIndex: 1000
          }}
          run/>
        : null}
    </StyledContainer>;
  }

  protected renderGameContent(): ReactElement {
    return <iframe src={"/games/" + this.props.game.id + "/index.html"} width={"100%"} height={640} frameBorder={0}/>;
  }
}